<template>
  <v-app>
    <v-app-bar app clipped-left flat color="secondary">
      <span style="padding-left: 35px">
        <img
          src="@/assets/logo.png"
          alt="Logotipo Norvoz Telecom"
          width="100"
        />
      </span>
      <v-chip
        class="ml-3 mt-5"
        color="green"
        outlined
        style="width: 70px; height: 15px"
      >
        <span style="color: green; font-size: 0.7em">v1.0.0-RC0</span>
      </v-chip>
      <v-spacer />

      <v-icon v-show="registrado" color="green darken-2">
        mdi-brightness-1
      </v-icon>
      <v-icon v-show="!registrado" color="red darken-2">
        mdi-brightness-1
      </v-icon>
      <v-btn
        v-show="iconSonido"
        @click="sonido()"
        class="mx-2"
        fab
        dark
        text
        small
        color="grey"
      >
        <v-icon>mdi-volume-high </v-icon>
      </v-btn>

      <v-btn
        v-show="!iconSonido"
        @click="sonido()"
        class="mx-2"
        fab
        dark
        small
        color="grey"
        text
      >
        <v-icon>mdi-volume-variant-off</v-icon>
      </v-btn>

      <v-btn color="white" @click="cerrar()" target="_blank" text>
        <span class="mr-2">{{ this.extension }}</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- <v-navigation-drawer app clipped flat dark permanent style="overflow:hidden;">
      <v-divider class="mb-1" />
      <v-list-item>
        <v-list-item-content>
          <v-btn color="secondary" @click="inicio()"> Inicio </v-btn>
        </v-list-item-content>
      </v-list-item>

      <p align="center" style="color: white">
        <v-icon color="" size="18px" style="padding-bottom: 2%"
          >mdi-clock</v-icon
        >
        Últimas llamadas
      </p>

      <v-container id="scroll-target" class="overflow-y-auto" style="height:88%;">
        <v-row
          v-scroll:#scroll-target="onScroll"
          align="center"
          justify="center"
         
        >
          <v-list-item v-for="item in items" :key="item.title">
            <v-list-item-icon>
              <v-icon
                color="primary"
                v-show="item.dcontext == contrato + '-incoming'"
                >mdi-phone-incoming</v-icon
              >
              <v-icon
                color="primary"
                v-show="item.dcontext == contrato + '-outgoing'"
                >mdi-phone-outgoing</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-show="item.dcontext == contrato + '-incoming'"
                >{{ item.src }}</v-list-item-title
              >
              <v-list-item-title
                v-show="item.dcontext == contrato + '-outgoing'"
                >{{ item.dst }}</v-list-item-title
              >
            </v-list-item-content>
            <v-btn color="secondary" @click="ver(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-list-item>
        </v-row>
      </v-container>
    </v-navigation-drawer> -->
  </v-app>
</template>

<script>
export default {
  components: {




  },
  name: "App",
  data: () => ({
    extension: "",
    contrato: "",
    url: process.env.VUE_APP_API_URL,
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    items: [],
    benched: 10,
    offsetTop: 10050,
    iconSonido: false,
  }),
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    cerrar() {
      let myInstance = this.$store.getters.getinstanciaPhone;
      myInstance.disconnect();
      this.$store.dispatch("setEstasRegistrado", false);
      this.$store.dispatch("setestadoCerrar", true);
      this.$store.dispatch("setinstanciaPhone", "");

      localStorage.removeItem("UserStorage");
      this.$router.push("/").catch((err) => {});
    },
    sonido() {
      let datos = this.$store.getters.getbeep;

      if (datos) {
        this.iconSonido = false;
        this.$store.dispatch("setbeep", false);
      } else {
        this.$store.dispatch("setbeep", true);
        this.iconSonido = true;
      }
    },
    llamadas() {
      let datos = this.$store.getters.getSipuser;
      let extension = datos.sipuser[0].user + "@" + datos.sipuser[0].domain;
      this.extension = extension;

      // const goExtension = async () => {
      //   let config = {
      //     headers: {
      //       Accept: "application/json",
      //       Authorization: this.$store.getters.getToken,
      //     },
      //   };
      //   try {
      //     return await this.$axios.get(
      //       this.url + "llamadas/extension/50",
      //       config
      //     );
      //   } catch (error) {}
      // };

      // const processExtensionResult = async () => {
      //   const extension_result = await goExtension();
      //   if (extension_result) {
      //     this.$store.dispatch("setRecargaListado", false);
      //     this.items = extension_result.data.llamadas.data;
      //     this.$store.dispatch("setLlamadas", this.items);
      //     this.contrato = this.extension.split("@")[0].split("-")[0];
      //   }
      // };
      // processExtensionResult();
    },
    historico() {
      this.$router.push("/historico").catch((err) => {});
    },
    inicio() {
      this.$store.dispatch("setNumeroLlamar", "");
      this.$router.push("/PhoneLlamar").catch((err) => {});
    },

    ver(item) {
      this.$store.dispatch("setNumeroLlamar", "item");
      this.$store.dispatch("setNumeroLlamar", item);
      this.$router.push("/historico").catch((err) => {});
    },
  },
  computed: {
    registrado() {
      return this.$store.getters.getEstasRegistrado;
    },

    // Vrecarga() {
    //   return this.$store.getters.getRecargaListado;
    // },
  },
  watch: {
    Vrecarga(value) {
      if (value) {
      }
      this.items = [];

      this.llamadas();
    },
  },
  mounted() {
    // this.registrar();
  },
  created() {
    this.llamadas();
  },
};
</script>
